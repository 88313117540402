import "./App.css";
import Chat from "./assets/chat.png";
import Expert from "./assets/expert.png";
import Daily from "./assets/daily.png";
import { portfolios, services } from "./utils/constants";
import Portfolio from "./assets/portfolio-image.png";
import { useEffect, useRef, useState } from "react";
import BlackLogo from "./assets/Agetware/black-green.svg";
import WhiteLogo from "./assets/Agetware/white-green.svg";
import Checked from "./assets/checked.png";
import Close from "./assets/close.png";
import { isMobileWeb } from "./utils/check-mobile";
import ReCAPTCHA from "react-google-recaptcha";
import Footer from "./footer";
import { Link } from "react-router-dom";
function Privacy() {
  const [scrollActive, setScrollActive] = useState(false);
  const [logo, setLogo] = useState(BlackLogo);
  const [showThankyou, setShowThankYou] = useState(false);
  const [isnavOpen, setIsNavOpen] = useState(false);
  const handleScroll = () => {
    setIsNavOpen(false);
    const position = window.scrollY;
    if (position > 60) {
      setScrollActive(true);
      //   setLogo(BlackLogo);
    } else {
      setScrollActive(false);
      //   setLogo(WhiteLogo);
    }
  };

  const scrollTo = (elementId) => {
    const element = document.getElementById(elementId);
    element.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  useEffect(() => {
    window.addEventListener("click", function (e) {
      if (!document.getElementById("agetware-nav").contains(e.target)) {
        setIsNavOpen(false);
      }
    });
    return () => {
      window.removeEventListener("click", () => {});
    };
  }, []);

  return (
    <div className="App h-full flex flex-col flex-1">
      <div className=" relative h-80">
        <nav
          id="agetware-nav"
          className={`fixed w-full ${
            // scrollActive
               "bg-background-neutral-primary text-text-black nav-shadow"
              //  "bg-background-neutral-primary"
          }`}
        >
          <div className="flex justify-between items-center py-30 px-30 relative w-full ">
            <div>
              <Link to={"/"}>
                <img src={logo} className="w-150" alt="agetWare" />
              </Link>
            </div>
            {!isMobileWeb() ? (
              <div className="nav-items md:flex text-16  hidden">
                <Link
                  className="mx-10 cursor-pointer"
                  to="/"
                  onClick={() => scrollTo("services-agetware")}
                >
                  Services
                </Link>
                <Link
                  className="mx-10 cursor-pointer"
                  onClick={() => scrollTo("portfolio-agetware")}
                >
                  Portfolio
                </Link>
                <Link
                  className="mx-10 cursor-pointer"
                  to={"/"}
                  onClick={() => scrollTo("contact-agetware")}
                >
                  Contact us
                </Link>
              </div>
            ) : (
              <i
                className="fa fa-bars text-30 menu"
                onClick={() => setIsNavOpen(!isnavOpen)}
              ></i>
            )}
            <div
              className={`absolute bg-background-neutral-primary w-full left-0 top-80 py-20 ${
                isnavOpen ? "h-auto" : "h-0 hidden"
              }`}
            >
              <Link
                className="mx-10 cursor-pointer text-text-black text-right my-10 mx-40 menu-item"
                to={"/"}
                onClick={() => scrollTo("services-agetware")}
              >
                Services
              </Link>
              <Link
                className="mx-10 cursor-pointer text-text-black text-right my-10 mx-40 menu-item "
                to={"/"}
                onClick={() => scrollTo("portfolio-agetware")}
              >
                Portfolio
              </Link>
              <Link
                className="mx-10 cursor-pointer text-text-black text-right my-10 mx-40 menu-item"
                to="/"
                onClick={() => scrollTo("contact-agetware")}
              >
                Contact us
              </Link>
            </div>
          </div>
        </nav>
      </div>
      <div className="bg-background-green  w-full self-end flex-1 flex flex-col items-center justify-center mt-50 min-h-200">
        <div className="text-text-black font-700 text-40">Privacy Policy</div>
      </div>
      <div className="px-50 py-60 text-left">
        {/* <div className="text-20 font-700 my-10">Privacy Policy for AgetWare</div> */}
        <div>
          At AgetWare, accessible from agetware.com, one of our main priorities
          is the privacy of our visitors. This Privacy Policy document contains
          types of information that is collected and recorded by AgetWare and
          how we use it.
        </div>
        <div>
          If you have additional questions or require more information about our
          Privacy Policy, do not hesitate to contact us. This Privacy Policy
          applies only to our online activities and is valid for visitors to our
          website with regards to the information that they shared and/or
          collect in AgetWare. This policy is not applicable to any information
          collected offline or via channels other than this website.
        </div>
        <div className="text-20 font-700 my-10">Consent</div>
        <div className="section-content">
          By using our website, you hereby consent to our Privacy Policy and
          agree to its terms.
        </div>
        <div className="text-20 font-700 my-10">Information we collect</div>
        <div className="section-content">
          If you contact us directly, we may receive additional information about
        you such as your name, email address, phone number, the contents of the
        message and/or attachments you may send us, and any other information
        you may choose to provide.
        </div>
        <div className="text-20 font-700 my-10">How we use your information</div>
        <div className="section-content">
          We use the information we collect in various ways, including to:
        <ul>
          <li>Provide, operate, and maintain our website</li>
          <li>Improve, personalize, and expand our website</li>
          <li>Understand and analyze how you use our website</li>
          <li>Develop new products, services, features, and functionality</li>
          <li>
            Communicate with you, either directly or through one of our
            partners, including for customer service, to provide you with
            updates and other information relating to the website, and for
            marketing and promotional purposes
          </li>
          <li>Send you emails</li>
          <li>Find and prevent fraud</li>
        </ul>
        </div>
        <div className="text-20 font-700 my-10">Log Files</div>
        <div className="section-content">
          AgetWare follows a standard procedure of using log files. These files
          log visitors when they visit websites. All hosting companies do this
          and a part of hosting services' analytics. The information collected
          by log files include internet protocol (IP) addresses, browser type,
          Internet Service Provider (ISP), date and time stamp, referring/exit
          pages, and possibly the number of clicks. These are not linked to any
          information that is personally identifiable. The purpose of the
          information is for analyzing trends, administering the site, tracking
          users' movement on the website, and gathering demographic information.
        </div>
        <div className="text-20 font-700 my-10">Advertising Partners Privacy Policies</div>
        <div className="section-content">
          You may consult this list to find the Privacy Policy for each of the
          advertising partners of AgetWare.
        </div>
        <div className="section-content">
          Third-party ad servers or ad networks uses technologies like cookies,
          JavaScript, or Web Beacons that are used in their respective
          advertisements and links that appear on AgetWare, which are sent
          directly to users' browser. They automatically receive your IP address
          when this occurs. These technologies are used to measure the
          effectiveness of their advertising campaigns and/or to personalize the
          advertising content that you see on websites that you visit.
        </div>
        <div className="section-content">
          Note that AgetWare has no access to or control over these cookies that
          are used by third-party advertisers.
        </div>
        <div className="text-20 font-700 my-10">Third Party Privacy Policies</div>
        <div className="section-content">
          AgetWare's Privacy Policy does not apply to other advertisers or
          websites. Thus, we are advising you to consult the respective Privacy
          Policies of these third-party ad servers for more detailed
          information. It may include their practices and instructions about how
          to opt-out of certain options.{" "}
        </div>
        <div className="section-content">
          You can choose to disable cookies through your individual browser
          options. To know more detailed information about cookie management
          with specific web browsers, it can be found at the browsers'
          respective websites.
        </div>
        <div className="text-20 font-700 my-10">Changes to This Privacy Policy</div>
        <div className="section-content">
          We may update our Privacy Policy from time to time. Thus, we advise
          you to review this page periodically for any changes. We will notify
          you of any changes by posting the new Privacy Policy on this page.
          These changes are effective immediately, after they are posted on this
          page.
        </div>
        <div className="text-20 font-700 my-10">Contact Us</div>
        <div className="section-content">
          If you have any questions or suggestions about our Privacy Policy, do
          not hesitate to contact us.
        </div>
      </div>
      <div className=" w-full self-end">
        <Footer />
      </div>
    </div>
  );
}

export default Privacy;
