import "./App.css";
import Chat from "./assets/chat.png";
import Expert from "./assets/expert.png";
import Daily from "./assets/daily.png";
import {  portfolios, services } from "./utils/constants";
import Portfolio from "./assets/portfolio-image.png";
import { useEffect, useRef, useState } from "react";
import BlackLogo from "./assets/Agetware/black-green.svg";
import WhiteLogo from "./assets/Agetware/white-green.svg";
import Checked from "./assets/checked.png";
import Close from "./assets/close.png";
import { isMobileWeb } from "./utils/check-mobile";
import ReCAPTCHA from "react-google-recaptcha";
import Footer from "./footer";
import {Link} from "react-router-dom"
function App() {
  const recaptcha = useRef();
  const [scrollActive, setScrollActive] = useState(false);
  const [logo, setLogo] = useState(WhiteLogo);
  const [showThankyou,setShowThankYou] = useState(false)
  const [isnavOpen,setIsNavOpen] = useState(false)
  const handleScroll = () => {
    setIsNavOpen(false)
    const position = window.scrollY;
    if (position > 60) {
      setScrollActive(true);
      setLogo(BlackLogo);
    } else {
      setScrollActive(false);
      setLogo(WhiteLogo);
    
    }
  };
  
  const scrollTo = (elementId) => {
    const element = document.getElementById(elementId);
    element.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };

  }, []);
  useEffect(()=>{window.addEventListener('click', function(e){   
    if (!document.getElementById('agetware-nav').contains(e.target) ){
      setIsNavOpen(false)
    }
   } )
  return () => {
    window.removeEventListener("click", ()=>{
    });
  };
},[]);
const submitContactUs = async(event)=>{
    event.preventDefault();
    const captchaValue = recaptcha.current.getValue();
    if (!captchaValue) {
      alert("Please verify the reCAPTCHA!");
    } else {
      // make form submission
      const  contact_name = document.getElementById("contact_name")
      const  contact_email = document.getElementById("contact_email")
      const  contact_message = document.getElementById("contact_message")
      const  contact_phone = document.getElementById("contact_phone")
      const res = await fetch("https://api.agetware.com/contact-us",{
        method:"POST",
        body:JSON.stringify({
          contact_name:contact_name.value,
          contact_email:contact_email.value,
          contact_phone:contact_phone.value,
          contact_message:contact_message.value
        })
      })
     if(res.status === 200){
      setShowThankYou(true)
     }
 
    }

}
  return (
    <div className="App h-full">
      <div className="banner-background">
        <nav
        id="agetware-nav"
        className={`fixed w-full ${
          scrollActive
            ? "bg-background-neutral-primary text-text-black nav-shadow"
            : ""
        }`}
        >
          <div   className="flex justify-between items-center py-30 px-30 relative w-full ">
          <div>
          <Link to={"/"}>
            <img src={logo} className="w-150" alt="agetWare" />
            </Link>
          </div>
          {!isMobileWeb()?<div className="nav-items md:flex text-16  hidden">
            <Link
              className="mx-10 cursor-pointer"
              to={"/"}
              onClick={() => scrollTo("services-agetware")}
            >
              Services
            </Link>
            <Link className="mx-10 cursor-pointer" onClick={()=>scrollTo("portfolio-agetware")}>Portfolio</Link>
            <Link
              className="mx-10 cursor-pointer"
              to={"/"}
              onClick={() => scrollTo("contact-agetware")}
            >
              Contact us
            </Link>
          </div>: <i className="fa fa-bars text-30 menu" onClick={()=>setIsNavOpen(!isnavOpen)}></i>}
          <div className={`absolute bg-background-neutral-primary w-full left-0 top-80 py-20 ${isnavOpen?"h-auto":'h-0 hidden'}`}>
          <div
              className="mx-10 cursor-pointer text-text-black text-right my-10 mx-40 menu-item"
              onClick={() => scrollTo("services-agetware")}
            >
              Services
            </div>
            <div className="mx-10 cursor-pointer text-text-black text-right my-10 mx-40 menu-item " onClick={()=>scrollTo("portfolio-agetware")}>Portfolio</div>
            <div
              className="mx-10 cursor-pointer text-text-black text-right my-10 mx-40 menu-item"
              onClick={() => scrollTo("contact-agetware")}
            >
              Contact us
            </div>
          </div>
          </div>
        </nav>

        <div className="flex flex-col justify-center items-center h-full">
          <div>
            <img src={WhiteLogo} className="w-150" alt="agetWare" />
          </div>
          <div className="text-28 font-500 mb-60">
            Empowering Digital Success
          </div>
          <div className="text-20 mb-20">
            We help businesses get their presence online
          </div>
          <div className="flex justify-center items-center text-20 mb-20">
            <div>APP</div>
            <div className="text-8 ml-5 mr-5">&#9679;</div>
            <div>WEB</div>
          </div>
          <div>
            <a
              href="#contact-agetware"
              className="bg-background-green px-20 py-10  rounded-5 mb-80"
            >
              Get in touch
            </a>
          </div>
        </div>
      </div>
      <div className="section-how-it-works py-40 px-20 md:px-100 pb-120 bg-background-green">
        <div
          className="text-40 md:text-56 font-900 my-20 mb-40"
          style={{ color: "#000" }}
        >
          How it works
        </div>
        {/* <div className="font-700 text-28 mb-30">Our Process</div> */}
        <div className="flex flex-col md:flex-row md:justify-between">
          <div className="mb-30 md:mb-0">
            <div className="text-left">
              <div className="flex items-start ">
                <div className="mr-20">
                  <div className="w-40 h-40 bg-background-neutral-primary flex items-center justify-center rounded-20 font-900">
                    1
                  </div>
                </div>
                <div>
                  <div className="text-20 font-700 mb-10">
                    DISCOVERY <br />
                    CALL
                  </div>
                  <div className="text-16 text-text-black">
                    Initial call or meeting to understand your business goals
                    and objectives.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-30 md:mb-0">
            <div className="text-left">
              <div className="flex items-start ">
                <div className="mr-20">
                  <div className="w-40 h-40 bg-background-neutral-primary flex items-center justify-center rounded-20 font-900">
                    2
                  </div>
                </div>
                <div>
                  <div className="text-20 font-700 mb-10">
                    UX/UI
                    <br />
                    ITERATIONS
                  </div>
                  <div className="text-16 text-text-black">
                    Start working with user journey mapping, wireframes/UX
                    design and then UI.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-30 md:mb-0">
            <div className="text-left">
              <div className="flex items-start ">
                <div className="mr-20">
                  <div className="w-40 h-40 bg-background-neutral-primary flex items-center justify-center rounded-20 font-900">
                    3
                  </div>
                </div>
                <div>
                  <div className="text-20 font-700 mb-10">
                    PRODUCT <br />
                    DEVELOPMENT
                  </div>
                  <div className="text-16 text-text-black">
                    Tech setup and start coding. Review on every stage to feel
                    the working product
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="text-left">
              <div className="flex items-start ">
                <div className="mr-20">
                  <div className="w-40 h-40 bg-background-neutral-primary flex items-center justify-center rounded-20 font-900">
                    4
                  </div>
                </div>
                <div>
                  <div className="text-20 font-700 mb-10">
                    READY TO <br /> LAUNCH
                  </div>
                  <div className="text-16 text-text-black">
                    That’s it! We help you launch your product on different
                    stores and platforms which can be globally scaled
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="section-feature px-20 md:px-100 py-75 flex flex-col items-start">
        <div className="text-56 font-700 text-text-grey">Features</div>
        <div className="text-28 font-700 text-text-black text-left my-20">
          What’s included in working with AgetWare.
        </div>
      
        <div className="flex flex-col md:flex-row flex-1 justify-between ">
          <div className="feature-card py-56 px-32 md:mr-20 rounded-40 flex flex-col flex-1 mb-20 md:mb-0">
            <img src={Chat} className="w-80" alt="chat" />
            <div className="text-text-seagreen my-16 text-left font-700 ">
              Iterative Process
            </div>
            <div className="text-left">
              Have the product look the way you want by being involved in the
              feedback and the development process
            </div>
          </div>
          <div className="feature-card py-56 px-32 md:mr-20 rounded-40 flex flex-col flex-1 mb-20 md:mb-0">
            <img src={Daily} className="w-80" alt="daily" />
            <div className="text-text-pink text-left my-16 font-700">
              Daily updates
            </div>
            <div className="text-left">
              Get friendly human support via Slack. You can talk to our
              developers and designers directly throughout the day.
            </div>
          </div>
          <div className="feature-card py-56 px-32 rounded-40 flex flex-col flex-1">
            <img src={Expert} className="w-80" alt="expert" />
            <div className="text-text-yellow my-16 text-left font-700">
              Expert suggestions
            </div>
            <div className="text-left">
              Get suggestions on how to get your processess online and how they
              can be optimised with the help of technology
            </div>
          </div>
        </div>
      </div>
      <div className="section-how-it-works px-20 md:px-100 pt-40 pb-65">
        <div
          className=" text-56 font-900 my-20"
          style={{ color: "#000" }}
          id="services-agetware"
        >
          Services
        </div>

        <div className="flex flex-col md:flex-row justify-between w-full mt-0 md:mt-40">
          {services.slice(0, 3).map((service) => (
            <div className="text-left md:w-350 mt-30 md:mt-0" key={service.title}>
              <div className="text-text-seagreen font-700 text-20 mb-16">
                {service.title}
              </div>
              <div className="text-16 text-text-lightblack ">
                {service.content}
              </div>
            </div>
          ))}
        </div>
        <div className="md:flex justify-between w-full mt-30">
          {services.slice(3, 6).map((service) => (
            <div className="md:w-350 text-left mt-30 md:mt-0 " key={service.title}>
              <div className="text-text-seagreen font-700 text-20 mb-16">
                {service.title}
              </div>
              <div className="text-16 text-text-lightblack ">
                {service.content}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="section-how-it-works px-20 md:px-100 pt-40 pb-65">
        <div
          className=" text-56 font-900 my-20"
          style={{ color: "#000" }}
          id="portfolio-agetware"
        >
          Portfolio
        </div>

        <div className="flex flex-col md:flex-row justify-center items-center md:justify-between w-full mt-0 md:mt-40 flex-wrap">
          {portfolios.map((portfolio) => (
            <div className="text-left flex flex-col mt-30 md:mt-20 w-350" key={portfolio.title}>
              <div className="flex items-center justify-center" style={portfolio.isRounded? {width:"350px",height:"241px",objectFit:"contain",background:portfolio.background,boxShadow: "rgba(149, 157, 165, 0.2) 40px 8px 24px" ,padding:"1rem",borderTopLeftRadius:20,borderTopRightRadius:20,border:"1px solid black",textAlign:"center"}:{}}>
                <img src={portfolio.image} alt={portfolio.title} />
                </div>
              <div className="flex items-start mt-10 justify-between">  
                <div>
              <div className="text-text-seagreen font-700 text-20  mb-5">
                {portfolio.title}
              </div>
              <div className="text-14 text-text-darkgray ">
                {portfolio.content}
              </div>
              </div>
              <div>
             {portfolio?.link? <button className="bg-background-green text-text-white text-12 py-2 rounded-10 px-10"><a href={portfolio.link} target="_blank" rel="noreferrer">Explore</a></button>:null}
              </div>
              </div>
            </div>
          ))}
        </div>
       
      </div>
      <div
        className="section-contact-us bg-background-seagreen md:flex"
        id="contact-agetware"
      >
        <form className="px-20 md:px-100 py-72 flex-1 flex flex-col " onSubmit={submitContactUs}>
          <div className="text-text-white text-16 font-500 text-left">
            Launch your product NOW <br />
            Partner with us
          </div>
          <div className="text-28 font-700 text-text-white text-left mt-20 mb-10">
            Get in touch<span className="tex-background-green">.</span>
          </div>
          <div className="relative mt-30">
            <div className="input-wrapper">
              <input
                type="text"
                id="contact_name"
                className="form-control"
                placeholder="Name"
                autoComplete="off"
              />
              <label htmlFor="contact_name" className="control-label">
                Name
              </label>
            </div>
          </div>
          <div className="relative mt-20">
            <div className="input-wrapper ">
              <input
                type="text"
                id="contact_phone"
                className="form-control"
                placeholder="Phone"
                autoComplete="off"
              />
              <label htmlFor="contact_phone" className="control-label">
                Phone
              </label>
            </div>
          </div>
          <div className="relative mt-20">
            <div className="input-wrapper ">
              <input
                type="text"
                id="contact_email"
                className="form-control"
                placeholder="Email"
                autoComplete="off"
              />
              <label htmlFor="contact_email" className="control-label">
                Email
              </label>
            </div>
          </div>
          <div className="relative  mt-20">
            <div className="input-wrapper">
              <input
                type="text"
                id="contact_message"
                className="form-control"
                placeholder="How can we help you?"
                autoComplete="off"
              />
              <label htmlFor="contact_message" className="control-label">
                How can we help you?
              </label>
            </div>
          </div>
          <div className="mt-10">
          <ReCAPTCHA ref={recaptcha} sitekey={process.env.REACT_APP_SITE_KEY} />
          </div>
          <div className="text-left mt-40 ">
            <button type="submit" className="border-1 bg-transparent border-text-white py-12 px-57 text-16 text-text-white font-500 rounded-5" onClick={(e)=>submitContactUs(e)}>
              Submit
            </button>
          </div>
       
        </form>
        <div className="flex-1">
          <img src={Portfolio} className="w-full h-full" alt="contact-us" />
        </div>
      </div>
     <Footer/>
     {showThankyou?<div
        className="fixed top-0 bottom-0 left-0 right-0 h-full"
        style={{ background: "rgba(21, 30, 38, 0.6)", zIndex: "99999" }}
      >
        <div className="bg-background-neutral-primary modal-content rounded-20">
          <div className="flex flex-row justify-end pr-20 pt-20">
          <img src={Close} alt="checked" className="cursor-pointer" onClick={()=>setShowThankYou(false)}/>
          </div>
       
          <div className="flex flex-col items-center  px-40 py-50">
   
          <img src={Checked} alt="checked" />
          <div className="text-30 font-900 mt-30">
              Thank you!
          </div>
          <div className="text-20">We’ll reach out to you soon
          </div>
          </div>
        </div>
      </div>:null}
    </div>
  );
}

export default App;
