import Ojam from "../assets/landing-o-jam_2023-10-07/landing-o-jam.png"
import Emtropy from "../assets/landing-emtropy_2023-10-07/landing-emtropy.png"
import Goalteller from "../assets/landing-goal-teller_2023-10-07/landing-goal-teller.png"
import Early from "../assets/landing-early_2023-10-07/landing-early.png"
import Trishan from "../assets/TrishanLogoAsset.png"
import wedigit from "../assets/logo.302f0e4c11b39ee972c23436ef307938.svg"
export const services = [
  {
    title: "APP DEVELOPMENT",
    content:
      "Expertise in the characteristics and best practices unique to each native platform ensures that our custom apps exceed user expectations every time.",
  },
  {
    title: "WEB DEVELOPMENT",
    content:
      "The Web remains the most open and flexible runtime available today. We ensure that web applications exceed users expectations in the mobile age, including accessibility and responsive design.",
  },
  {
    title: "PRODUCT DESIGN",
    content:
      "User-centered design, where visual UI is informed by UX and content strategy, creates seamless and intuitive digital experiences.",
  },
  {
    title: "RESEARCH + INSIGHTS",
    content:
      "Our practice bridges rich qualitative research with quantitative data to bring scientific rigor and a deep understanding of user needs to high-velocity software design and development.",
  },
  {
    title: "STRATEGY + INNOVATION",
    content:
      "We cultivate a deep understanding of your industry, brand, customers, and vision in order to differentiate your product—and ultimately your brand—in the marketplace.",
  },
  {
    title: "CLOUD DEVELOPMENT",
    content:
      "Our practice bridges rich qualitative research with quantitative data to bring scientific rigor and a deep understanding of user needs to high-velocity software design and development.",
  },
];

export const portfolios = [
  {
    title:"Goalteller",
    image:Goalteller,
    content:"UI/UX . APP . WEB",
    link:"https://goalteller.com/"
  },{
    title:"Trishan School of Music",
    image:Trishan,
    content:"UI/UX . APP . WEB",
    isRounded:"false",
    background:"black",
    link:"https://www.trishanschool.com/"
  },
  {
    title:"wedigit",
    image:wedigit,
    content:"WEB",
    isRounded:"false",
    background:"black",
    link:"https://www.wedigit.tech/"
  },{
  title:"OJam",
  image:Ojam,
  content:"UI/UX . APP . WEB"
},{
  title:"EARLY",
  image:Early,
  content:"UI/UX . APP . WEB"
},
{
  title:"Emtropy",
  image:Emtropy,
  content:"WEB"
}
]
