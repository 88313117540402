import WhiteLogo from "./assets/Agetware/white-green.svg";
import {Link} from "react-router-dom"

const Footer = ()=>{
    return ( <div className="footer px-60 py-27 bg-background-dark-base-primary md:flex justify-between items-start"> 
    <div className="flex flex-col items-start">
    <Link to={"/"}>
      <img src={WhiteLogo} height={40} width={200} alt="logo"/>
      </Link>
      <div className="px-10 flex items-center mt-20">
      <i class="fa fa-map-marker text-text-white text-20 mr-10"></i>
      <div className="text-text-white">Bangalore</div>
      </div>
    </div>
    <div className="flex flex-col items-start">
    <div className="text-text-white leading-40 font-700">Legal</div>
      <div className=" flex items-center mt-20">

      <div className="text-text-white">
        <Link to={"/privacy-policy"}>
        Privacy Policy
        </Link>
  </div>
      </div>
    </div>
    <div className="flex flex-col items-start">
    <div className="text-text-white leading-40 font-700">Contact us on</div>
      <div className="px-10 flex items-center mt-20">
      <i class="fa fa-phone text-text-white text-20 mr-10"></i>
      <div className="text-text-white">
        <a href="tel:+918123189089">+918123189089</a></div>
      </div>
      <div className="px-10 flex items-center mt-20">
      <i class="fa fa-envelope text-text-white text-20 mr-10"></i>
      <div className="text-text-white"><a href="mailTo:nkrttechsolutions@gmail.com">nkrttechsolutions@gmail.com</a></div>
      </div>
    </div>

</div>)
}

export default Footer